<template>
    <data value="">
    <!-- <h1 class="one">FRIENDLY DIAMONDS</h1>-->
        <HomeBannerVideo :isMobile="isMobile" />

        <!-- <div class="padding_block"></div> -->
        <!-- <div class="padding_block"></div> -->
        <!-- <HomeBanner v-else /> -->
        <!-- <BlackFriday v-if="$country.coupon" /> -->
        <!-- <div class="container full_width">
            <div class="padding_block"></div>
        </div> -->

        <div class="mb-4"></div>
        <HomeFreeProductOffer v-if="$country.free_product" />
        <div v-if="$country.free_product" class="mb-4"></div>
    
        <div class="container">
            <HomePresetBanner />
        </div>
        <!-- <SampleSwiperSlider /> -->
       <!-- <div class="padding_block"></div> -->
       <!-- <SampleSwiperSlider :page="'index'" /> -->

       <HomeShopByCategory :page="'index'"  />
       <div v-if="$country.is_india" class="padding_block"></div>
       <!--  -->
       <div v-if="!$country.is_india" class="padding_block"></div>

       <section class="shape_block_mod" v-if="$country.is_india">
        <div class="container">
            <CommonShapeSlider />
        </div>
        </section>

        <div class="container" v-if="$country.is_india">
            <div class="padding_block"></div>
            <HomeCreateYourOwnIndia />
            
            <!-- <div class="padding_block"></div> -->
        </div> 
          <!-- <div class="container full_width">
            <div class="padding_block" v-if="$country.is_india"></div>
            <AboutFriendlyTwoIndia v-if="$country.is_india" :country="$country.location"  />
            <div  v-if="!$country.is_india" class="padding_block"></div>
        </div> -->

        <section class="shape_block_mod" v-if="!$country.is_india">
            <div class="container">
                <CommonShapeSlider />
            </div>
        </section>

        <div class="container" v-if="!$country.is_india">
            <div class="padding_block"></div>
            <HomeCreateYourOwnIndia />
                <!-- <CreateYourOwn /> -->
        </div>


        <div class="container full_width" v-if="!$country.is_india">
            <div class="padding_block"></div>
                <HomeShopByStyle  />
        </div> 
    
        <div class="container full_width" v-if="!$country.is_india">
            <!-- <div class="padding_block"></div> -->
                <HomeShopByPrice :page="'index'"  />
        </div>

        <div class="container full_width" v-if="!$country.is_india">
            <div class="padding_block"></div>
                <LandingInStockBanner />
        </div>

        <!-- <container fluid class="full_width">
            <div class="padding_block"></div>
            <DiamondDifference />
        </container> -->
        <!-- <container>
            <div class="padding_block" v-if="$country.is_india"></div>
            <InspireDesign v-if="$country.is_india" />
            <div class="padding_block"></div>
        </container> -->
        <!-- <div class="padding_block"></div> -->
        <!-- <AboutFriendlyTwo v-if="!$country.is_india" :country="$country.location" /> -->

        <div class="container">
            <div class="padding_block"></div>
            <HomePrBlogs />
        </div>

           <!-- <container>
            <div class="padding_block" v-if="!$country.is_india"></div>
            <AboutFriendlyTwoIndia v-if="!$country.is_india" :country="$country.location" />
        </container> -->

        <div class="container">
            <div class="padding_block"></div>
            <HomeTestimonialsIndia v-if="$country.is_india" />
            <HomeTestimonials v-else />
        </div>

        <div class="container">
            <div class="evertree_block" v-if="$country.evertreen">
                <EvertreeBanner />
            </div>
        </div>

        <div class="container">
            <div class="padding_block"></div>
                <HomeTrustpilotReview v-if="$country.trustpilot" />
            <div class="padding_block" v-if="$country.trustpilot"></div>
        </div>
       
        <HomeBlog :type="'homepage'" />
    </data>
</template>

<script setup>
    useFetchMeta( useNuxtApp(), useRuntimeConfig() );
    const { $country } = useNuxtApp()
    const { isMobile, isDesktop : isDesktop, isSafari,isCrawler, isTablet, isAndroid  } = useDevice();

    // defineRouteRules({
    //     prerender: true
    // })

    // app.$axios.get('/cart')
    // const isDesktop = ref(false);
    // if (import.meta.server) {
    //     const headers = useRequestHeaders(['user-agent']);
    //     const userAgent = headers['user-agent'];

    //     if (userAgent && userAgent.includes('Mobi')) {
    //         isDesktop.value = false;
    //     }
    //     } else {
    //     onMounted(() => {
    //         if (window.innerWidth < 768) {
    //         isDesktop.value = false;
    //         }
    //     });
    // }
    // console.log( '------------------ device ', 'ismobile -->',isMobile ,'isDesktop->', isDesktop, 'isSafari->', isSafari,  'isCrawler->', isCrawler , 'isAndroid -->',isAndroid      )

    onMounted( () => {
        const runtimeConfig = useRuntimeConfig();

        // console.log( '------------------ device ', 'ismobile -->',isMobile ,'isDesktop->', isDesktop, 'isSafari->', isSafari,  'isCrawler->', isCrawler , 'isAndroid -->',isAndroid      )
    } )


</script>

<style scoped>

.container{
    max-width: 1470px;
    width: 100%;
}
.shape_block{
    background: #faf9f7;
    padding: 3% 0;
}
.padding_block{
    padding-top: 2%;
    padding-bottom: 2%;
}
.evertree_block {
    margin: 35px 0;
}
.shape_block_mod{
    padding: 3% 0;
    background: url(../assets/shapes/background.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
@media(max-width: 1470px){
    .container{
        width: 80%;
    }
   
}
@media(max-width: 1250px){
    .container{
        width: 90%;
    }
}
@media(max-width: 992px){
    
    .padding_block {
        padding-top: 1%;
        padding-bottom: 1%;
    }
    .shape_block{
        padding: 3%;
    }
}
@media(max-width:512px){
   
    .shape_block_mod {
        padding: 5% 0 !important;
    }

    .shape_block_mod .container {
        padding: 0;
    }

    .shape_block_mod .shape_block {
        padding: 0;
    }
}
@media(max-width:767px){
    .container {
        width: 100%;
      
    }
    .shape_block {
        padding:0;
    }
    .evertree_block {
        margin: 15px 0;
    }
}
</style>