<template>
    <div class="py-4">
            <h4 class="mb-4 text-center font-active">Shop Gifts By Price</h4>
        <transition name="fade">
            <swiper
            :modules="[SwiperAutoplay]"
            :autoplay="{
              delay: 2500,
              disableOnInteraction: false
            }"
            :spaceBetween="10"
            :loop="true"
            :loopAdditionalSlides="0"
            :breakpoints="{
              365: {
                slidesPerView:1.1,
                slidesPerGroup:1,
                centeredSlides: true,
                spaceBetween: 50,
                loopedSlides: 1, 
              },  
              512: {
                slidesPerView: 3,
                slidesPerGroup: 1,
                autoplay: {
                  delay: 2500,
                  disableOnInteraction: false
                },
              },
            }"
            >
                <swiper-slide>
                  <NuxtLink :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-1000' } })">
                    <div class="img_box radious_eight">
                      <picture>
                        <source width="460" height="436" type="image/webp" srcset="~/assets/home/price/price1.webp">
                        <img loading="lazy" fluid width="460" height="436" type="image/jpeg" src="~/assets/home/price/price1.jpg" alt="Engagement Rings">
                      </picture>
                    </div>
                    <span>Gifts Under $1000</span>
                  </NuxtLink>
                </swiper-slide>
                <swiper-slide>
                  <NuxtLink :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-2000' } })">
                    <div class="img_box radious_eight">
                      <picture>
                        <source width="460" height="436" type="image/webp" srcset="~/assets/home/price/price2.webp">
                        <img loading="lazy" type="image/jpeg" fluid width="460" height="436" src="~/assets/home/price/price2.jpg" alt="Eternity Rings">
                      </picture>
                    </div>
                    <span>Gifts Under $2000</span>
                  </NuxtLink>
                </swiper-slide>
                <swiper-slide>
                  <NuxtLink :to="localePath({ name:'shop-all', params:{ start_with: 'gifts-under-3000' } })">
                    <div class="img_box radious_eight">
                      <picture>
                        <source width="460" height="436" type="image/webp" srcset="~/assets/home/price/price3.webp">
                        <img loading="lazy" type="image/jpeg" fluid width="460" height="436" src="~/assets/home/price/price3.jpg" alt="Diamond Pendants">
                      </picture>
                    </div>
                    <span>Gifts Under $3000</span>
                  </NuxtLink>
                </swiper-slide>
            </swiper>
        </transition>

        <div v-show="skeleton">
            <div class="row for_desktop">
                <div class="col-md-4" v-for="index in 3" :key="index">
                    <div class="prod_block">
                        <div class="prod_image skeleton_lg">
                            <!-- <b-skeleton-img class="mx-auto" width="100%" height="400px"></b-skeleton-img> -->
                        </div>
                        <div class="prod_image skeleton_md">
                            <!-- <b-skeleton-img class="mx-auto" width="100%" height="180px"></b-skeleton-img> -->
                        </div>
                        <!-- <b-skeleton class=" my-2" width="100%" height="40px"></b-skeleton> -->
                    </div>
                </div>
            </div>
            <div class="for_mobile">
                <div class="prod_block">
                    <div class="prod_image">
                        <!-- <b-skeleton-img class="mx-auto" width="100%" height="260px"></b-skeleton-img> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<script setup>
const props = defineProps({
  locale: String,
  page: String
})
const localePath = useLocalePath()
const skeleton = ref(true);

onMounted(() => {
  nextTick(() => {
    skeleton.value = false;
  });
});
</script>


<style scoped>

.transform-enter-active,
.transform-leave-active {
    transition: transform 0.5s linear;
    transform: translateY(-20px);
}

.transform-enter,
.transform-leave-to {
    transform: translateY(0px);

}

.slide_box{
    text-align: center;
    padding: 2%;
}


h4{
    font-size: 30px;
}
p{
    font-weight: 500;
    color: #000;
}
a{
    display: inline-block;
}
span{
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    display: block;
    text-align: center;
    color: #000;
    padding-top: 5%;
}

@media(max-width: 767px){
    h4{
        font-size: 24px;
    }
    p{
        font-size: 16px;
        padding-left: 5%;
        padding-right: 5%;
    }
}


/* --------animation---------- */
.img_box{
    overflow: hidden;
    max-width: 462px;
    max-height: 438px;
}
img{
    transition: 0.8s; 
    /* image-rendering: pixelated; */
}
.img_box img:hover{
    transform: scale(1.2);
    transition: 0.8s;  
}
.slide_box:hover span{
    color: var(--green);
}
/* --------animation---------- */
</style>